<template>
  <div class="d-flex align-center">
    <!-- Start: Branch filter -->
    <div class="d-flex">
      <div style="max-width: 220px">
        <v-select
          class="text-body-1 rounded-lg mt-0"
          dense
          flat
          hide-details
          v-model="selectValue"
          :items="branchSerialsListSelectOptions"
          item-text="branch_name"
          item-value="branch_id"
          :menu-props="{ maxWidth: '332px', nudgeTop: '-40px' }"
          no-data-text="Không có dữ liệu"
          placeholder="Chọn chi nhánh"
          solo
          @change="selectBranchFilter($event)"
        >
          <template v-slot:selection="data">
            <div class="py-2">
              <span class="black--text">
                {{ data.item.branch_name }}
              </span>
              <v-chip
                class="font-weight-bold justify-center rounded ml-1 px-1"
                color="primary"
                dark
                style="height: 20px; min-width: 18px"
                small
              >
                {{
                  data.item.branch_id === "all"
                    ? data.item.quantity
                    : data.item.serials.length
                }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item="data">
            <div class="py-2">
              <span>
                {{ data.item.branch_name }}
              </span>
              <v-chip
                class="font-weight-bold justify-center rounded ml-1 px-1"
                color="primary"
                dark
                style="height: 20px; min-width: 18px"
                small
              >
                {{
                  data.item.branch_id === "all"
                    ? data.item.quantity
                    : data.item.serials.length
                }}
              </v-chip>
            </div>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedBranchFilter: {
      type: Object,
      required: true
    },
    clearDataSelect: {
      type: Boolean
    }
  },
  data() {
    return {
      selectValue: ""
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    branchSerialsList() {
      return this.$store.getters["MODAL_SERIAL_PICKER/branchSerialsList"];
    },
    branchSerialsListSelectOptions() {
      let arr = [...this.branchSerialsList];
      const item = {
        branch_id: "all",
        branch_name: "Toàn hệ thống",
        quantity: 0
      };

      item.quantity = this.branchSerialsList.reduce((counter, item) => {
        if (item.serials) {
          return counter + item.serials.length;
        }
      }, 0);

      arr.unshift(item);

      return [item, ...arr];
    },
    filteredBranchSerialsList() {
      return this.$store.getters[
        "MODAL_SERIAL_PICKER/filteredBranchSerialsList"
      ];
    }
  },
  watch: {
    clearDataSelect: {
      handler: function() {
        this.selectValue = "";
        this.selectBranchFilter("all");
      },
      deep: true
    }
  },
  created() {
    if (this.branches && this.branches.length === 0)
      this.$store.dispatch("BRANCH/getAllBranches");
  },
  methods: {
    selectBranchFilter(branchId) {
      const branchSerialsList = JSON.parse(
        JSON.stringify(this.branchSerialsList)
      );
      let arr = [];

      this.$emit("updateSelectedBranchFilter", {
        type: 3,
        value: branchId
      });

      if (branchId === "all") {
        arr = branchSerialsList;
      } else {
        arr = branchSerialsList.filter(item => item.branch_id === branchId);
      }

      // Update filteredBranchSerial
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setFilteredBranchSerialsList",
        arr
      );
    }
  }
};
</script>
