var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"white pb-4",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-body-1 font-weight-bold pb-0"},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.visible = !_vm.visible}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.visible ? "mdi-menu-down" : "mdi-menu-right")+" ")])],1),_vm._v(" "+_vm._s(_vm.branchSerials.branch_name)+" ("+_vm._s(_vm.filteredSerialByStatus.length)+") "),_c('v-spacer')],1),(_vm.visible)?_c('v-row',{staticClass:"px-4 mt-1"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"9"}},[(_vm.filteredSerialByStatus.length > 0)?_c('div',{staticClass:"scrollable"},_vm._l((_vm.filteredSerialByStatus),function(item,index){return _c('v-tooltip',{key:index,attrs:{"bottom":"","color":"white","content-class":"elevation-4 px-2","disabled":item.exchange_status === 0 && item.order_status === 0,"nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-2 mt-2 mr-2",class:(hover && item.order_status !== 1 && item.status === 1) ||
                  _vm.pickedSerialNumbers.includes(item.serial_number)
                    ? 'tp-btn--text primary'
                    : item.order_status === 1 || item.status === -13
                    ? 'grey lighten-4 grey--text'
                    : 'tp-btn--text grey lighten-4',attrs:{"dark":hover,"label":""}},'v-chip',attrs,false),on),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.serial_number)+" ")])])]}}],null,true)})]}}],null,true)},[(item.exchange_status === 1)?_c('div',{staticClass:"black--text text-body-2 text-center",staticStyle:{"width":"180px"}},[_vm._v(" Đang được chuyển đến "+_vm._s(item.exchange_goods.branch_nhap_name)+" trong phiếu chuyển "+_vm._s(item.exchange_goods.code)+" ")]):_vm._e(),(item.order_status === 1)?_c('div',{staticClass:"black--text text-body-2 text-center",staticStyle:{"width":"180px"}},[_vm._v(" Đang được gán trong đơn xử lý "+_vm._s(item.order.order_handle_code)+" của đơn hàng "+_vm._s(item.order.order_code)+" ")]):_vm._e()])}),1):_c('v-card',{staticClass:"grey lighten-4",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-body-1 justify-center"},[_vm._v("Không có serials nào.")])],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('div',{staticClass:"scrollable yellow lighten-5 fill-height rounded px-3 py-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Khách đặt hàng: "+_vm._s(_vm.branchSerials.stock_info.ordersCount)+" ")]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Đang về: "+_vm._s(_vm.branchSerials.stock_info.pos.length)+" ")]),_c('ul',{staticClass:"ml-0"},_vm._l((_vm.branchSerials.stock_info.pos),function(item,index){return _c('li',{key:index,staticClass:"text-body-2 py-1"},[_vm._v(" "+_vm._s(item.qty)+" "),_c('router-link',{staticClass:"font-weight-bold primary--text text-decoration-none",attrs:{"target":"_blank","to":{
                  name: 'transaction_goods-receipts_purchase-orders-detail',
                  params: {
                    purchaseOrderId: item.id
                  }
                }}},[_vm._v(_vm._s(item.code))]),_vm._v(" dự kiến về "+_vm._s(_vm._f("formatDate")(item.est_date))+" "+_vm._s(_vm.getTimeLeft(item.est_date) ? _vm.getTimeLeft(item.est_date) : null)+" ")],1)}),0)])])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }