<template>
  <v-list class="card--sidebar rounded pt-4" color="white" dense>
    <v-list-item-group
      v-model="computedSelectedSerialStatus"
      active-class="font-weight-bold"
    >
      <v-list-item v-for="(item, index) in serialStatusList" :key="index" link>
        <v-list-item-content>
          <span>
            {{ item.text }}
            <v-chip
              class="font-weight-bold justify-center rounded ml-1 px-1"
              color="primary"
              dark
              style="height: 20px; min-width: 18px"
              small
            >
              {{ countFilteredBranchSerialsListByStatus(item.value) }}
            </v-chip>
          </span>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: {
    selectedBranchFilter: {
      type: Object,
      required: true
    },
    selectedSerialStatus: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      serialStatusList: [
        { value: "all", text: "Tất cả" },
        { value: 1, text: "Còn hàng" },
        { value: -13, text: "Trưng bày" },
        { value: "exchange_status", text: "Đang chuyển" }
      ]
    };
  },
  computed: {
    computedSelectedSerialStatus: {
      get() {
        const arr = this.serialStatusList.map(item => item.value);

        return arr.indexOf(this.selectedSerialStatus);
      },
      set(val) {
        this.$emit(
          "updateSelectedSerialStatus",
          this.serialStatusList[val].value
        );
      }
    },
    filteredBranchSerialsList() {
      return this.$store.getters[
        "MODAL_SERIAL_PICKER/filteredBranchSerialsList"
      ];
    }
  },
  methods: {
    countFilteredBranchSerialsListByStatus(status) {
      if (status === 1) {
        return this.filteredBranchSerialsList.reduce(
          (counter, item) => counter + item.statistics.sale_serials,
          0
        );
      }
      if (status === -13) {
        return this.filteredBranchSerialsList.reduce(
          (counter, item) => counter + item.statistics.sample_serials,
          0
        );
      }
      if (status === "exchange_status") {
        return this.filteredBranchSerialsList.reduce(
          (counter, item) => counter + item.statistics.delivery_serials,
          0
        );
      }
      return this.filteredBranchSerialsList.reduce(
        (counter, item) => counter + item.serials.length,
        0
      );
    }
  }
};
</script>
